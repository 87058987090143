// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-menu-item:active {
  background-color: rgba(60, 174, 240, 0.452);
}
.active_sidebar_menu {
  color: var(--primary-color) !important;
  font-size: 16px !important;
}
.active_sidebar_menu .ant-menu-item-icon {
  font-size: 16px !important;
}
.ant-menu-item-active {
  background-color: #f7f7f7;
}
.ant-menu-item-active .ant-menu-item-icon {
  color: var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/menu/menu.less"],"names":[],"mappings":"AACA;EACE,2CAAA;AAAF;AAEA;EACE,sCAAA;EACA,0BAAA;AAAF;AAFA;EAII,0BAAA;AACJ;AAEA;EACE,yBAAA;AAAF;AADA;EAGI,2BAAA;AACJ","sourcesContent":["\n.ant-menu-item:active {\n  background-color: rgba(60, 174, 240, 0.452);\n}\n.active_sidebar_menu {\n  color: var(--primary-color) !important;\n  font-size: 16px !important;\n  .ant-menu-item-icon {\n    font-size: 16px !important;\n  }\n}\n.ant-menu-item-active {\n  background-color: rgb(247, 247, 247);\n  .ant-menu-item-icon {\n    color: var(--primary-color);;\n  }\n}\n@primary-color: #033364;@link-color: #1890ff;@grey-color: rgb(0,0,0,0.25);@border-radius-base: 3px;@border-color-base: #bab4b4;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
