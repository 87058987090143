import { relationActionTypes } from "@/redux/actions/relationActions/relationActionTypes";

const initialRelationState = {
  relations: [],
};

const relationReducer = (state = initialRelationState, action) => {
  const { payload } = action;
  console.log("action.type", action.type);
  console.log("payload", payload);
  switch (action.type) {
    case "SET_RELATIONS":
      return {
        ...state,
        relations: payload,
      };
    case relationActionTypes.ADD_RELATION:
      const obj = {
        ...state,
        relations: [...state.relations, payload],
      };
      console.log("obj ", obj);
      return obj;
    case relationActionTypes.REMOVE_RELATION:
      return {
        ...state,
        relations: state.relations.filter(
          (relation) => relation.id !== payload.id
        ),
      };
    case relationActionTypes.REMOVE_RELATION_TARGET:
      return {
        ...state,
        relations: state.relations.filter(
          (relation) => relation.target !== payload.target
        ),
      };
    case relationActionTypes.REMOVE_RELATION_SOURCE:
      return {
        ...state,
        relations: state.relations.filter(
          (relation) => relation.source !== payload.source
        ),
      };
    case relationActionTypes.UPDATE_RELATION:
      return {
        ...state,
        relations: state.relations.map((relation) =>
          relation.id === payload.id
            ? { ...relation, quantity: relation.quantity - 1 }
            : relation
        ),
      };
    case relationActionTypes.CLEAR_RELATION:
      return {
        ...state,
        relations: [],
      };
    default:
      return state;
  }
};

export default relationReducer;
