// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.right_content {
  height: 64px !important;
  margin-right: 10px;
  /*background-color: white;*/
  display: flex;
  align-items: center;
}
.right_content .avatar {
  margin-left: 20px;
  width: 48px;
  height: 48px;
}
.right_content .title {
  color: #FFF;
  font-size: 16px;
  margin: auto 10px;
}
.dropdown {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/style.less"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,kBAAA;EACF,2BAA2B;EACzB,aAAA;EACA,mBAAA;AACJ;AACA;EACI,iBAAA;EACA,WAAA;EACA,YAAA;AACJ;AACA;EACI,WAAA;EACA,eAAA;EACA,iBAAA;AACJ;AACA;EACI,eAAA;AACJ","sourcesContent":[".right_content {\n    height: 64px !important;\n    margin-right: 10px;\n    /*background-color: white;*/\n    display: flex;\n    align-items: center;\n}\n.right_content .avatar {\n    margin-left: 20px;\n    width: 48px;\n    height: 48px;\n}\n.right_content .title {\n    color: #FFF;\n    font-size: 16px;\n    margin: auto 10px;\n}\n.dropdown {\n    cursor: pointer;\n}\n\n@primary-color: #033364;@link-color: #1890ff;@grey-color: rgb(0,0,0,0.25);@border-radius-base: 3px;@border-color-base: #bab4b4;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
