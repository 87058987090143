// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
.site-layout .site-layout-background {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.logo {
  height: 64px !important;
  display: flex;
  background-color: var(--primary-color);
  text-align: center !important;
  align-items: center !important;
}
.logo .avatar {
  margin-left: 20px;
  width: 48px;
  height: 48px;
}
.logo .title {
  color: white;
  font-size: 24px;
  margin: auto 20px;
}
.menuIcon {
  font-size: 20px;
  margin: auto 20px;
  color: rgba(255, 255, 255, 0.65);
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mainBox {
  margin: 0 !important;
}
.mainBox .ant-card-bordered {
  border: none !important;
}
.mainBox .ant-card {
  border-radius: 2px !important;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/layout.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,YAAA;EACA,oCAAA;AACJ;AAEA;EACI,gBAAA;EACA,wCAAA;AAAJ;AAMA;EACI,uBAAA;EAEA,aAAA;EAEA,sCAAA;EACA,6BAAA;EACA,8BAAA;AANJ;AAQA;EACI,iBAAA;EACA,WAAA;EACA,YAAA;AANJ;AAQA;EACI,YAAA;EACA,eAAA;EACA,iBAAA;AANJ;AAQA;EACI,eAAA;EACA,iBAAA;EACA,gCAAA;AANJ;AAQA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;AANJ;AAQA;EACI,oBAAA;AANJ;AAQA;EACI,uBAAA;AANJ;AAQA;EACI,6BAAA;AANJ","sourcesContent":["#components-layout-demo-side .logo {\n    height: 32px;\n    margin: 16px;\n    background: rgba(255, 255, 255, 0.3);\n}\n\n.site-layout .site-layout-background {\n    background: #fff;\n    box-shadow: 0 2px 4px rgba(0,0,0, .1);\n\n}\n//.slider {\n//    box-shadow: 2px 0 4px rgba(0,0,0, .5);\n//}\n.logo {\n    height: 64px !important;\n    ///*background-color: white;*/\n    display: flex;\n    //box-shadow: none;\n    background-color: var(--primary-color);;\n    text-align: center !important;\n    align-items: center !important;\n}\n.logo .avatar {\n    margin-left: 20px;\n    width: 48px;\n    height: 48px;\n}\n.logo .title {\n    color: white;\n    font-size: 24px;\n    margin: auto 20px;\n}\n.menuIcon {\n    font-size: 20px;\n    margin: auto 20px;\n    color: rgba(255, 255, 255, 0.65);\n}\n.header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n.mainBox {\n    margin: 0 !important;\n}\n.mainBox .ant-card-bordered {\n    border: none !important;\n}\n.mainBox .ant-card {\n    border-radius: 2px !important;\n}\n\n@primary-color: #033364;@link-color: #1890ff;@grey-color: rgb(0,0,0,0.25);@border-radius-base: 3px;@border-color-base: #bab4b4;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
